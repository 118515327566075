import React from "react"
import './header.css'

const Header = () => (
  <header>
    <h1 style={{ margin: 0 }}>
      Vijay Hawoldar
    </h1>
  </header>
)

export default Header
