import React from "react"
import { withPrefix } from 'gatsby'

const About = () => (
  <>
    <section>
      <h2>A bit about me</h2>
      <p>
        I"m Vijay!
        (<a href="https://itinerarium.github.io/phoneme-synthesis/?w=/'vɪdʒaɪ/" target='_blank' rel='noopener noreferrer'><i>/'vɪdʒaɪ/</i></a>)
      </p>
      <p>
        I am a senior full stack developer at Kanso where I've been making apps (web and mobile) since the company first started 10 years ago.
        I am passionate about technology and love my work finding and creating solutions to complex problems whilst bringing our client's ideas to life.
      </p>
    </section>

    <section>
      <h3>What I'm good at</h3>
      <p>I have extensive experience with Ruby, and Rails (since 2007!)</p>
      <p>I've written my fair share of JavaScript both old and new, and more recently I've been honing my skills with Node.js, React and React Native.</p>
      <p>I'm an advocate of TDD and have been practicing it since I first picked up Ruby/Rails.</p>
      <p>
        I have leadership experience, managing junior to mid level developers, leading projects, peer reviewing code and architecting solutions.
      </p>
    </section>

    <section>
      <h3>What I'm interested in</h3>
      <p>
        I'm currently expanding my knowledge around Serverless technologies, in particular using AWS Lambda functions and how they can be used to deliver applications at scale.
        In addition to that, I'm also working on a pet project using Ruby to generate SVGs from a given set of data.
      </p>
    </section>


    <section>
      <h3>What I do for fun</h3>
      <p>
        I live in Cardiff with my wife and our <a href={withPrefix('/img/haldi.JPG')} target='_blank' rel="noopener noreferrer">dog</a>, where
        I enjoy trail running along the costal paths and in the many forests we have in and around Wales.
        Otherwise I can often be found at my computer playing the latest games, or travelling to new places in our campervan.
      </p>
    </section>
  </>
)

export default About
