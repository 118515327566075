import React from "react"
import './footer.css'

import { FaEnvelope, FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa'
import { IconContext } from "react-icons"


const Footer = () => (
  <IconContext.Provider value={{ size: '3em', className: "global-class-name" }}>
    <footer>
      <h3>Want to talk?</h3>
      <div>
        <a href='https://twitter.com/vijayh'>
          <FaTwitter />
        </a>
        <a href='https://github.com/vijayh'>
          <FaGithub />
        </a>
        <a href='https://linkedin.com/in/vijay-hawoldar'>
          <FaLinkedin />
        </a>
        <a href='mailto:hello@vijay.dev'>
          <FaEnvelope />
        </a>
      </div>
    </footer>
  </IconContext.Provider>
)

export default Footer
