import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about"
import Image from "../components/image"

const IndexPage = () => (
  <Layout>
    <SEO title='Vijay Hawoldar' keywords={[`vijay`, `hawoldar`, `engineer`, `developer`, `senior`, `web`, `cardiff`, `full stack`]} />
    <section className='intro'>
      <div className='description'>
        <p>
          I'm a full stack engineer with over 10 years experience solving problems and building things using:
        </p>
        <ul>
          <li>Ruby on Rails</li>
          <li>Node.js</li>
          <li>React</li>
          <li>Typescript</li>
          <li>React Native</li>
        </ul>
        <p>
          I'm based in Cardiff, UK and I work with some amazing people at <a href="https://kanso.io">Kanso</a> to deliver solutions to clients
          such as <a href='https://amplyfigroup.com'>Amplyfi</a>, <a href='https://cafedirect.co.uk'>CafeDirect</a>, <a href='https://manorcottages.co.uk'>Manor Cottages</a> and <a href='https://piratestudios.co.uk'>Pirate Studios</a>.
        </p>
      </div>
      <div className='image' style={{maxWidth: '300px', minWidth: '250px'}}>
        <Image alt='my face' />
      </div>
    </section>

    <section className='about'>
      <About />
    </section>
  </Layout>
)

export default IndexPage
